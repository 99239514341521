.notesApp {
    transition: all .2s ease-in-out;
}

#task-notes {
    width: 260px;
    position: fixed;
    bottom: 0;
    right: 0;
    background: white;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    padding: 32px;
    border-radius: 24px 0 0 0;
    z-index: 101;
    border-top: 6px solid #208FFE;
    display: none;
}

#task-notes h3 {
    margin: 0 0 0 0;
}


.notes-width {
    width: calc(50% + 8px);
}

#notes-app {
    overflow-y: scroll;
    height: 324px;
    padding-right: 12px;
    justify-content: flex-start;
    padding-bottom: 8px;
    margin-top: 32px;
}

#notes-app::-webkit-scrollbar {
    background: white;
    width: 8px;
}

#notes-app::-webkit-scrollbar-track {
    background: #d9d9d9;
    border-radius: 4px;
}

#notes-app::-webkit-scrollbar-button {
    background: #fff;
}

#notes-app::-webkit-scrollbar-thumb {
    background-color: #a1a1a1;
    border-radius: 4px;
    width: 8px;
}

.note {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.note-bubble {
    background: #c1e0ff;
    padding: 12px 16px;
    margin: 8px 8px 8px 0;
    border-radius: 24px 24px 0 24px;
}

.note:nth-of-type(2n) .note-bubble {
    background: #d1d1d1;
}

.note-author {
    font-size: 14px;
    color: #d1d1d1;
    margin: -2px 8px 0px 0px;
}

#note-send-app {
    position: relative;
    border-bottom: 1px solid #d1d1d1;
    padding: 0 0 4px 0;
    margin-top: 32px;
    width: calc(100% + 10px);
}

.note-send-msg {
    margin: 0 32px 0 0;
    font-size: 16px;
    color: #333;
    border: 0;
    padding: 8px;
    width: 216px;
}

.note-send-msg::placeholder {
    color: #d1d1d1;
    font-size: 12px;
}

.note-send-msg:focus {
    outline: none;
}

.note-send-button {
    background: #208FFE;
    border-radius: 22px;
    cursor: pointer;
    position: absolute;
    top: -8px;
    right: -4px;
}

.note-send-button:hover {
    background: #03dac5;
    transition: .2s all ease-out;
}

.note-send-button span {
    display: flex;
    font-size: 32px;
    color: #fff;
    padding: 8px;
    align-items: center;
    justify-content: center;
}

.notes-icon {
    margin: 0 4px 0 0;
}

.min-max-wrapper {
    position: absolute;
    top: 16px;
    right: 16px;
}

.minimized {
    /*max-height: 10px !important;*/
    padding: 0;
    /*display: none !important;*/
    bottom: -440px !important;
    transition: all .2s cubic-bezier(0.4, 0.6, 0.2, 1);
}

.minimized {
    bottom: 0px;
}

.minimize-icon, .maximize-icon {
    cursor: pointer;
}

.minimize-icon:hover, .maximize-icon:hover {
    opacity: .2;
    transition: opacity .2s ease-in-out;
}

.disabled {
    opacity: .2;
    cursor: default;
}

.disabled:hover {

}


