.taskStatus {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 380px;
  background: white;
  border-radius: 0 0 24px 24px;
  margin: 0px auto -54px auto;
  padding: 8px 0;
}

.step {
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-title {
  font-size: 12px;
  opacity: 0.7;
  margin-top: 4px;
}

.step-circle {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 4px solid #f40000;
  box-sizing: border-box;
}

.step-done .step-circle {
  background: #208ffe;
  border: none;
}

.step-done .step-title {
  opacity: 0.7;
}

.step-active .step-circle {
  background: #ffffff;
  border: 4px solid #208ffe;
}

.step-pending .step-circle {
  background: #f40000;
  border: none;
}
