.formJSON {
    display: flex;
    align-self: center;
    background: white;
    padding: 48px 64px 64px 64px;
    margin: -64px 0 64px 0;
    border-radius: 6px;
    border: 1px solid #e1e1e1;
    align-self: center;
    min-height: 284px;
    justify-content: flex-start !important;
    position: relative;
}

.formJSON input::file-selector-button {
    background: #0014cc;
    padding: 16px;
    margin: 0 16px 0 0;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
}

.formJSON input:not(.other) {
  color: transparent;
}

.formJSON .file-info {
  color: #0014cc;
}

.formJSON hr {
    display: none !important;
}

.rjsf {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 0 0 0;
}

.rjsf div {
    width: 100%;
}

.css-178yklu {
    display: none;
}

.control-label {
    display: none;
}

ul.nav.nav-pills {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: flex-start;
    padding: 0;
    margin: 0 0 32px -4px;
}

div:has(> .nav-pills) {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: flex-start;
}

.nav-pills li {
    background: #9d9d9d;
    margin: 4px;
    list-style: none;
    padding: 4px 8px 4px 6px;
    color: white;
    cursor: pointer;
    border-radius: 4px;
}

li.active {
    background: #208ffe !important;
}

#root-title {
    display: none;
}

.MuiTypography-h5 {
    margin-top: 32px !important;
    margin-bottom: -32px !important;
    font-size: 48px !important;
    font-weight: 100 !important;
    letter-spacing: 4px !important;
}

.css-9mgopn-MuiDivider-root {
    display: none;
}

.formJSON .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  margin-bottom: 20px;
}

.formJSON .Mui-error {
  color: #FF0000;
  font-size: 20px;
  font-weight: bold;
}

.center-text {
    text-align: center !important;
    display: inline-block;
    width: 100%;
}

.file-info li button {
    display: none;
}

input[id*="_file"] {
  padding: 14px 0 40px 14px;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  min-height: auto;
  height: 30px;
}

.last-submitted {
    margin: -16px 0 0 0;
    font-style: italic;
    color: #666;
    font-size: 12px;
}