.formSplitter {
    align-content: center;
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 32px 64px;
    padding: 0;
    position: absolute;
    z-index: 10;
    width: 100%;
}

.formSplitter li {
    align-items: center;
    justify-content: center;
    background: #f2f2f2;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    list-style: none;
    margin: 0 2px;
    padding: 8px 16px;
    transition: background-color .2s ease-in-out;
    text-align: center;
    width: 85%;
}

.formSplitter .sectionSwitch {
  position: absolute;
  z-index: 10;
  width: 100%;
  margin-top: 200px;
}

li.arrowRight {
    border-radius: 4px 20px 20px 4px;
    padding: 8px 8px 8px 6px;
    display: none;
}

li.arrowLeft {
    border-radius: 20px 4px 4px 20px;
    padding: 8px 6px 8px 8px;
    display: none;
}

.formSplitter li:hover {
    background: #d9d9d9;
}

.activeElement {
    display: flex !important;
    padding: 16px 0 10px 0;
}

.hiddenElement {
    display: none !important;
}

.lightUp {
    background: #0014cc !important;
    color: white;
}

.is-sticky {
    position: fixed;
    top: 0px;
    z-index: 100;
    background: white;
    padding: 16px;
    box-shadow: 0 0 10px rgb(0 0 0 / 30%);
    border-radius: 0 0 8px 8px;
    transition: all 0.1s ease-in-out;
    margin: 0;
    width: 70%;
}

.is-not-sticky {
    /*position: relative;*/
}

/*overrides*/

.MuiGrid-item {
    padding: 0 !important;
    margin: 0 !important;
}

.css-mhc70k-MuiGrid-root { margin: 40px 0 0 -4px !important; }

.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.css-isbt42 {
    margin: 32px 0 16px -4px !important;
}

.css-flr9ij {
    /*display: none;*/
    margin-top: 64px;
}

.invisible {
    opacity: 0 !important;
}

.formSplitter .disabled {
  background: #C9C9C9;
  color: rgb(69, 69, 69);
}

.sectionStatusQuestion {
  font-size: 36px;
  font-weight: 600;
  margin-right: 25px;
}