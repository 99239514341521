.autoSave {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 4px 24px;
  background: #0080ff;
  font-size: 0.75em;
  color: #fff;
  z-index: 1;
  border-radius: 0 24px 0 0;
}
