.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 300px 0 0 0;
  padding: 64px;
  background: #f8f8f8;
  box-sizing: border-box;
}

.loader h3 {
  margin: 0 0 32px 0;
}

.token {
  font-size: 11px;
}
