.hero {
    background-image: url('https://ok9static.oktacdn.com/fs/bco/7/fs0ademolvmWyskwE417');
    background-size: cover;
    background-position: center;
    min-height: 380px;
    color: white;
}

.hero h1 {
    margin-top: -72px;
}

.hero h1, .hero p {
    text-align: center;
}

.hero p {
    margin: 24px 0 32px 0;
    max-width: 324px;
}

.progress-label {
    margin: 12px 0;
    font-size: 12px;
}

.small-p {
    font-size: 13px;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 30px;
    background: #208FFE;
    color: #fff;
    cursor: pointer;
    border-radius: 24px;
    transition: .2s all;
    margin: -4px auto 8px auto;
    text-decoration: none;
    border: none;
    font-size: 18px;
    margin-top: 20px;
}

.button:hover {
    background: #03dac5;
}

.submitButton {
    min-width: 224px !important;
    background-color: #0014cc;
}

.inactive {
    opacity: .6;
    cursor: default;
}

.lockIcon, .checkIcon {
    margin-top: 12px;
    font-size: 20px !important;
}

.barHidden {
    /*display: none !important;*/
    opacity: 0;
}

.barVisible {
    /*display: block !important;*/
    opacity: 1;
}

.partnerLogo {
    position: absolute;
    top: 64px;
    right: 0px;
    background: white;
    padding: 0 16px;
    border-radius: 4px 0 0 4px;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
    z-index: 100;
    transition: all .2s ease-in-out;
}

.partnerLogo img {
    width: 120px;
}

.overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
}

.Mui-disabled {
    color: rgba(255, 255, 255, .5) !important;
    background-color: rgba(0, 20, 100, .4) !important;
    opacity: 1;
}
