.formTable {
  /*padding: 24px;*/
  font-family: "Roboto", sans-serif;
  margin: 0;
  box-sizing: border-box;
}

#container {
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

#container h2 {
  font-size: 54px;
  font-weight: 300;
  margin: 24px 0 0 0;
  color: #333;
  border-bottom: 1px dotted #333;
  text-align: left;
  display: inline;
  width: 100%;
}

td {
  width: 50%;
}

td {
  padding-top: 20px;
  padding-bottom: 20px;
  background: rgba(0, 0, 0, 0.03);
  margin: 4px;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

td:nth-of-type(2n) {
  background: #ecf5ff;
}

.container {
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

#container h1 {
  display: none;
}

.table {
  width: 100%;
  border: 0px solid black;
  border-collapse: collapse;
}

.table-header {
  display: flex;
  width: 100%;
  padding: 18px 0;
}

.table-row {
  display: flex;
  width: 100%;
}

.table-row:nth-of-type(2n) {
  border-top: 1px solid pruple;
}

/*
    Chips
*/
.chip {
  display: inline;
  background: #208ffe;
  border-radius: 16px;
  padding: 8px 16px;
  color: white;
  font-size: 0.8em;
  margin: 4px;
}
/* .table-row:nth-of-type(odd) {
     background: #eee;
 }*/

.section {
  width: 100%;
}
.section:first-child {
}
.section-1 td {
}
.section-2 td {
  background-color: #fff;
}
.section-3 td {
  background-color: #fff;
}
.section-4 {
}

td b {
  display: flex;
  align-items: center;
  flex-direction: unset;
  flex-wrap: wrap;
}
