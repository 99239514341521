.header {
  display: flex;
  background: #f8f8f8;
  height: 76px;
}

.header h2 {
  font-size: 36px;
  font-weight: 100;
  margin: 16px 0;
  letter-spacing: 4px;
  text-align: center;
}

#logo-groupm {
  height: 30px;
  margin: 0 24px;
}

#badge-fusion {
  height: 24px;
  margin: 0 24px;
}
