.tableView {
    position: absolute;
    top: 8px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tableView span {
    margin: 0 4px;
    font-size: 15px;
    opacity: .6;
}

.toggleOffIcon {
    font-size: 64px !important;
    transition: all .2s;
    cursor: pointer;
    opacity: .8;
}

.active {
    color: #5e00b5;
    opacity: 1;
    transform: rotate(180deg);
}
