/*Grid*/

.max-width-1440 {
  max-width: 1440px;
}

div[class*="col-"] {
  display: flex;
  justify-content: center;
  position: relative;
}

.col-1-1 {
  width: 100%;
}
.col-1-2 {
  width: 50%;
}
.col-1-3 {
  width: 33.33%;
}
.col-1-4 {
  width: 25%;
}
.col-1-5 {
  width: 20%;
}
.col-2-3 {
  width: 66.66%;
}
.col-3-5 {
  width: 60%;
}
.col-auto {
  width: auto;
}

.flex-direction-column {
  flex-direction: column;
}
.flex-direction-row {
  flex-direction: row;
}
.flex-align-start {
  align-items: flex-start;
}
.flex-align-end {
  align-items: flex-end;
}
.flex-align-center {
  align-items: center;
}
.flex-justify-start {
  justify-content: flex-start;
}
.flex-center-all {
  justify-content: center;
  align-items: center;
}

.padding-24 {
  padding: 24px;
}
.padding-48 {
  padding: 48px;
}
.padding-64 {
  padding: 64px;
}
.padding-right0 {
  padding-right: 0 !important;
}
.padding-right48 {
  padding-right: 48px;
}
.padding-right64 {
  padding-right: 64px;
}
.padding-left48 {
  padding-left: 48px;
}
.padding-left64 {
  padding-left: 64px;
}
.margin-negative-48 {
  margin-top: -48px;
}

.bump-up {
  z-index: 10;
}
.bump-up-more {
  z-index: 11;
}
